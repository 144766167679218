import React from 'react';
import Logo from "../../images/logo.svg";
import { Link } from "gatsby";
import './thankYouForOrdering.scss';

const ThankYouForOrdering = () => {
    return (
      <>
        <div className="thank-you-for-ordering">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <img className="w-25 w-sm-100" alt="logo" src={Logo} />
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-sm-12 d-flex justify-content-center">
                <p>
                  Thank you for ordering and supporting Tree-Nation. We are looking
                  forward to see you at Re:factor 2022! Check your mailbox for
                  tickets and more information.
                </p>
              </div>
              <div className="col-sm-12 d-flex justify-content-center">
                <Link to="/">Return to home</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="filler"></div>
      </>
    );
}

export default ThankYouForOrdering;
