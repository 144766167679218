import * as React from "react"
import SEO from "../components/seo/Seo"
import Layout from "../components/layout/Layout"
import NavBar from "../components/navbar/NavBar";
import ThankYouForOrdering from "../components/thankYouForOrdering/ThankYouForOrdering";


const ThankYouPage = () => {
    return (
        <Layout>
            <SEO title="Thank you" />
            <NavBar />
            <ThankYouForOrdering />
        </Layout>
    )
}

export default ThankYouPage
